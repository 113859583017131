import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({layout, breakpoints, spacing}) => ({
  slideWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      marginBottom: spacing(12.5),
    },
    '& > div:first-of-type': {
      paddingRight: '11%',
      flex: 1.4,
    },
    '& > div:last-of-type': {
      paddingRight: 0,
      flex: 1,
    },
    [breakpoints.down('sm')]: {
      height: 'auto',
      flexDirection: 'column-reverse',
      margin: spacing(6.9, 'auto'),
      maxWidth: layout.mainCarousel.slideWrapper.maxWidth,
      '& > div:first-of-type': {
        paddingRight: 'inherit',
      },
    },
  },
  reverted: {
    [breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      '& > div:first-child': {
        paddingRight: 0,
        flex: 1.4,
      },
      '& > div:last-child': {
        paddingRight: '11%',
        flex: 1,
      },
    },
  },
  slideLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    '& h3': {
      fontSize: 26,
      marginBottom: spacing(2.5),
    },
    '& h3:not(:first-child), & > button, & > a': {
      marginTop: spacing(2.5),
    },
    '& h4': {
      marginBottom: spacing(2.5),
    },
    '& h5': {
      marginBottom: spacing(0.75),
    },
    '& p': {
      marginBottom: spacing(2.5),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
    [breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },
  slideRight: {
    maxWidth: layout.mainCarousel.slideRight.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      margin: spacing(0, 'auto', 2, 'auto'),
      display: 'inherit',
      flexDirection: 'inherit',
      justifyContent: 'inherit',
    },
  },
  noImage: {
    maxWidth: '100%',
  },
  isLogo: {
    maxWidth: '20%',
  },
  slideImageContainer: {
    width: 300,
    height: 300,
    maxWidth: '100%',
    '& > *': {
      display: 'block',
      overflow: 'hidden',
      width: '100%',
      // height: '100%',
      [breakpoints.up('sm')]: {
        '& > video': {
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          position: 'sticky',
        },
      },
      [breakpoints.down('sm')]: {
        width: 'auto',
        height: 'inherit',
        overflow: 'hidden',
        display: 'grid',
        maxWidth: '100%',
        margin: spacing('auto', 'auto', 4, 'auto'),
        maxHeight: layout.mainCarousel.image.maxHeight,
        '& > video': {
          objectFit: 'cover',
          width: '100%',
        },
      },
    },
    [breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  hasBorderRadius: {
    '& div': {
      borderRadius: '10px 40px',
    },
    [breakpoints.up('sm')]: {
      width: '110%',
    },
  },
}))

export default useStyles
