import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import loadable from '@loadable/component'
import React from 'react'

import {
  allContentfulInnovationTechnologiesPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import ContentSection from 'components/UI/ContentSection'
import Hero from 'components/UI/Hero'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const InnovationTechnologies = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {
    imageOrVideo,
    metaDescription,
    metaTitle,
    pageTitle,
    shortDescription: {shortDescription},
    surtitle,
    contentSections,
  } = R.pathOr(null, ['allContentfulInnovationTechnologies', 'nodes', 0], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration
            webSiteName="keyrus"
            color="red"
            right={0}
            bottom={-160}
          />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          <Hero
            hat={surtitle}
            title={pageTitle}
            description={shortDescription}
            media={imageOrVideo}
            titleType="title"
          />
        </Section>
      </div>
      {R.map(
        content => (
          <Section hasVerySmallPadding>
            <ContentSection
              showContent={content.showContentSection}
              title={content.title}
              callToAction={content.callToAction}
              variation={content.variation}
              description={content.description}
              image={content.image}
              link={content.link}
              typeOfLink={content.typeOfLink}
              isOpenInNewWindow={content.isOpenInNewWindow}
              fileToDownload={content.fileToDownload}
              pageContext={pageContext}
              countryData={countryData}
            />
          </Section>
        ),
        contentSections,
      )}
    </>
  )
}

InnovationTechnologies.propTypes = {
  data: shape({
    allContentfulInnovationTechnologies:
      allContentfulInnovationTechnologiesPropTypes,
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export const pageQuery = graphql`
  query templateInnovationTechnologies(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulInnovationTechnologies(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        callToAction
        imageOrVideo {
          file {
            contentType
            url
          }
        }
        metaDescription {
          metaDescription
        }
        metaTitle
        pageTitle
        shortDescription {
          shortDescription
        }
        surtitle
        contentSections {
          callToAction
          description {
            raw
          }
          image {
            file {
              contentType
              url
            }
          }
          title
          variation
          link
          typeOfLink
          isOpenInNewWindow
          fileToDownload {
            file {
              contentType
              url
            }
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default InnovationTechnologies
