import {shape} from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import {heroPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Heading from 'components/UI/Heading'
import Media from 'components/UI/Media'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const Hero = ({
  ctaColor,
  ctaLink,
  ctaTitle,
  description,
  descriptionType,
  hasCtaOnRight,
  hasRoundCorner,
  hasSmallVerticalMargin,
  hat,
  isAlignCenter,
  media,
  offsetBottom,
  title,
  titleType,
  hasAutoWidth,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const renderMediaBlock = () =>
    media && (
      <div
        className={classNames(classes.slideImageContainer, {
          [classes.hasRoundCorner]: hasRoundCorner,
        })}
      >
        <Media data={media} />
      </div>
    )

  return (
    <div
      className={classNames(
        classes.slideWrapper,
        offsetBottom && classes.offsetBottom,
        isAlignCenter && classes.isAlignCenter,
        hasSmallVerticalMargin && classes.hasSmallVerticalMargin,
      )}
    >
      <div
        className={classNames(
          classes.slideLeft,
          hasAutoWidth && classes.autoWidth,
        )}
      >
        <Heading>
          {hat && <Title isCentered={!!isMobile} hat={hat} />}
          <div className={classes.slideDescriptionContainer}>
            <Title variant="h1" type={titleType} isCentered={!!isMobile}>
              {title}
            </Title>
            <Description type={descriptionType}>{description}</Description>
            {ctaLink && ctaTitle && !hasCtaOnRight && (
              <RoundButton color={ctaColor} arrow href={ctaLink}>
                {ctaTitle}
              </RoundButton>
            )}
          </div>
          {isMobile && renderMediaBlock()}
        </Heading>
      </div>
      <div className={classes.slideRight}>
        {hasCtaOnRight && !media && (
          <div className={classes.slideRightCta} style={{textAlign: 'center'}}>
            <RoundButton color={ctaColor} arrow href={ctaLink}>
              {ctaTitle}
            </RoundButton>
          </div>
        )}
        {!isMobile && renderMediaBlock()}
      </div>
    </div>
  )
}

Hero.propTypes = heroPropTypes.isRequired

Hero.defaultProps = {
  ctaColor: 'primary',
  ctaLink: '',
  ctaTitle: '',
  description: shape({description: ''}),
  descriptionType: 'largeDescription',
  hasCtaOnRight: false,
  hasRoundCorner: false,
  hasSmallVerticalMargin: false,
  hat: '',
  isAlignCenter: false,
  media: null,
  offsetBottom: false,
  title: '',
  titleType: 'largeTitle',
}

export default Hero
