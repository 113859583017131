import * as R from 'ramda'
import {shape} from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import {contentSectionPropTypes} from 'helpers/propTypes'
import Media from 'components/UI/Media'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'

import useStyles from './styles'

const ContentSection = ({
  hatTitle,
  title,
  callToAction,
  variation,
  description,
  image,
  isLogo,
  link,
  typeOfLink,
  isOpenInNewWindow,
  fileToDownload,
  countryData,
  anchor,
  showSection,
  isPaddingLess,
  isJustified,
  hasBorderRadius,
}) => {
  const classes = useStyles()

  const renderMediaBlock = () =>
    image &&
    variation !== 'No image' && (
      <div
        className={classNames(classes.slideRight, {
          [classes.noImage]: variation === 'No image',
          [classes.isLogo]: isLogo,
        })}
      >
        <div
          className={classNames({
            [classes.slideImageContainer]: !hasBorderRadius,
            [classes.hasBorderRadius]: hasBorderRadius,
          })}
        >
          <Media data={image} />
        </div>
      </div>
    )

  const linkUri = () => {
    if (countryData) {
      const {countryCode, navLocale, urlPrefix} = countryData

      if (typeOfLink === 'Internal link') {
        if (urlPrefix === 'worldwide') {
          return `/${countryCode}${link}`
        }

        return `/${countryCode}/${navLocale}${link}`
      }
      if (typeOfLink === 'External link') {
        return `//${link}`
      }
      if (typeOfLink === 'Link to a file') {
        return R.pathOr('', ['file', 'url'], fileToDownload)
      }
    }

    return ''
  }

  const fileName = R.pathOr(null, ['file', 'fileName'], fileToDownload)

  return (
    <>
      {showSection !== false && (
        <div
          className={classNames(classes.slideWrapper, {
            [classes.reverted]:
              variation === 'Image left' || variation === 'Left',
            [classes.noImage]: variation === 'No image',
          })}
          id={anchor}
          style={isPaddingLess ? {marginBottom: 0} : null}
        >
          {variation === 'No content' ? (
            <div className="sideWrapper">
              <div>
                <Title isCentered variant="h2">
                  {title}
                </Title>
              </div>
            </div>
          ) : (
            <>
              <div className={classes.slideLeft}>
                <Title
                  variant="h3"
                  type="mediumTitle"
                  fontFamily="medium"
                  hat={hatTitle}
                >
                  {title}
                </Title>
                {description && (
                  <RawText text={description} isJustified={isJustified} />
                )}
                {callToAction && typeOfLink !== 'Link to a file' && (
                  <RoundButton
                    color="secondary"
                    arrow
                    href={linkUri()}
                    externalLink={typeOfLink === 'External link'}
                    isClickable={isOpenInNewWindow}
                  >
                    {callToAction}
                  </RoundButton>
                )}
                {callToAction && typeOfLink === 'Link to a file' && (
                  <RoundButton
                    color="secondary"
                    arrow
                    href={linkUri()}
                    fileName={fileName}
                    isAction
                    isDownload
                  >
                    {callToAction}
                  </RoundButton>
                )}
              </div>
              {renderMediaBlock()}
            </>
          )}
        </div>
      )}
    </>
  )
}

ContentSection.propTypes = contentSectionPropTypes

ContentSection.defaultProps = {
  anchor: null,
  callToAction: '',
  description: shape({raw: ''}),
  hatTitle: '',
  image: null,
  isJustified: false,
  isLogo: false,
  isPaddingLess: false,
  showSection: true,
  title: '',
  variation: '',
}

export default ContentSection
